"use client";

import { Paths } from "../Paths";
import { Text } from "@egocentric-systems/ts-apis/types/v1/text_pb";
import { useCart } from "~/hooks/useCart";
import {
  CartItem,
  COOKIE_PREFERENCES_KEY,
  DEFAULT_COOKIE_EXPIRE,
  DEFAULT_COOKIE_MAX_AGE,
  EGOSYS_LNG_COOKIE,
  GeneratePathParams,
  SHIPMENT_METHOD_KEY,
} from "../definitions";
import { reservation } from "./reservation";
import { StripeElementLocale } from "@stripe/stripe-js";
import { PlainMessage, proto3 } from "@bufbuild/protobuf";
import { ShopLanguage } from "@egocentric-systems/ts-apis/shop_config/types/v1/common_pb";
import { parse } from "accept-language-parser";
import Cookies from "universal-cookie";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { ShipmentMethod } from "@egocentric-systems/ts-apis/shop_config/types/v1/shipment_pb";
import { toast } from "~/components/ui/use-toast";
import { gtmChangeLanguage } from "./gtm";
import { vouchers } from "./vouchers";
import { saveCartItems, saveDiscounts } from "./db";
import { createReserveOrCancelBundleRequest } from "./order";
import { ReserveSeatsResponse } from "@egocentric-systems/ts-apis/booking_gateway/v1/reservations_pb";
import { Event } from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import { hash } from "crypto";

dayjs.extend(timezone);
dayjs.extend(utc);

export const changeLanguage = (code: string) => {
  const cookies = new Cookies();

  if (typeof navigator === "undefined") return;
  const browserLng = parse(navigator.language)[0]?.code;
  const currentLng = getLocale();

  if (code === browserLng) {
    cookies.remove(EGOSYS_LNG_COOKIE, {
      path: "/",
      sameSite: "strict",
    });
  } else {
    cookies.set(EGOSYS_LNG_COOKIE, code, {
      path: "/",
      sameSite: "strict",
      expires: dayjs.utc().add(1, "year").toDate(),
      maxAge: dayjs.utc().add(1, "year").diff(dayjs(), "seconds"),
    });
  }

  gtmChangeLanguage(currentLng, code);
};

export function generatePath(path: Paths, params?: GeneratePathParams): string {
  let newPath: string = path;
  let hashTag = "";

  if (params !== undefined) {
    newPath = path.replace(/:(\w+)/g, (match, key) => {
      return params[key as keyof typeof params] || match;
    });
  }

  if (path !== Paths.ROOT && newPath.endsWith("/")) {
    newPath = newPath.slice(0, -1);
  }

  const hashIndex = newPath.indexOf("#");
  if (hashIndex !== -1) {
    hashTag = newPath.substring(hashIndex);
    newPath = newPath.substring(0, hashIndex);
  }

  if (typeof window === "undefined") {
    return newPath;
  }

  const url = new URL(window.location.toString());
  url.searchParams.delete("redirect");

  url.pathname = newPath;

  if (params?.redirect) {
    url.searchParams.set("redirect", encodeURIComponent(params.redirect));
  }

  return url.pathname + url.search + hashTag;
}

export function localeToStripeElementLocale(
  locale: string,
): StripeElementLocale {
  switch (locale) {
    case "en":
    case "de":
      return locale;

    default: {
      return "de";
    }
  }
}

export async function makeRequests(
  storedItems: CartItem[],
  temporaryItems: CartItem[],
): Promise<{ items: CartItem[] }> {
  const requests =
    temporaryItems.length > 0
      ? temporaryItems.map((item) => {
        const storedItem = storedItems.find(
          ({ section, price, eventOrBundle, series, seat, discount }) =>
            section.id === item.section.id &&
            price.id === item.price.id &&
            eventOrBundle.case === item.eventOrBundle.case &&
            eventOrBundle.value.id === item.eventOrBundle.value.id &&
            series.id === item.series.id &&
            seat?.id === item.seat?.id &&
            discount?.case === item.discount?.case &&
            discount?.value === item.discount?.value,
        );

        const amount = storedItem
          ? item.quantity - storedItem.quantity
          : item.quantity;

        return { ...item, amount };
      })
      : storedItems.map(
        ({
          section,
          price,
          eventOrBundle,
          series,
          quantity,
          seat,
          discount,
          upgrades,
        }) => ({
          section,
          price,
          eventOrBundle,
          series,
          amount: -quantity,
          seat,
          discount,
          upgrades,
        }),
      );

  const filteredRequests = requests.filter(({ amount }) => amount !== 0);

  if (filteredRequests.length === 0) {
    return { items: storedItems };
  }

  for (const {
    section,
    price,
    eventOrBundle,
    series,
    amount,
    seat,
    discount,
    upgrades,
  } of filteredRequests) {
    if (amount < 0) {
      const value = Math.abs(amount);

      if (eventOrBundle.case === "event") {
        await reservation.cancelSeats({
          reservationToken: useCart.getState().reservationToken ?? "",
          eventId: eventOrBundle.value.id,
          sectionId: section.id,
          seating: !seat
            ? { case: "seatCount", value }
            : { case: "seatIds", value: { ids: [seat.id] } },
        });
      } else {
        const cancelConfigs = createReserveOrCancelBundleRequest({
          section,
          price,
          eventOrBundle,
          series,
          quantity: value,
          seat,
          discount,
          upgrades,
        });

        for (const cancelConfig of cancelConfigs) {
          await reservation.cancelBundle({
            ...cancelConfig,
            reservationToken: useCart.getState().reservationToken ?? "",
          });
        }
      }

      if (discount) {
        const { removeVoucher, removeBenefit } = useCart.getState();

        if (discount?.case === "voucherId") {
          await vouchers.releaseVoucher({
            reservationToken: useCart.getState().reservationToken ?? "",
            voucherId: discount.value,
          });

          removeVoucher(discount.value);
        } else if (discount?.case === "benefitId") {
          await vouchers.releaseBenefit({
            reservationToken: useCart.getState().reservationToken ?? "",
            benefitId: discount.value,
          });

          removeBenefit(discount.value);
        }
      }

      const index = storedItems.findIndex(
        (item) =>
          item.section.id === section.id &&
          item.price.id === price.id &&
          eventOrBundle.case === item.eventOrBundle.case &&
          eventOrBundle.value.id === item.eventOrBundle.value.id &&
          item.series.id === series.id &&
          item.seat?.id === seat?.id &&
          item.discount?.case === discount?.case &&
          item.discount?.value === discount?.value,
      );

      if (index !== -1) {
        storedItems[index].quantity -= value;
      }
    } else {
      if (eventOrBundle.case === "event") {
        const response = await reservation
          .reserveSeats({
            reservationToken: useCart.getState().reservationToken ?? "",
            eventId: eventOrBundle.value.id,
            sectionId: section.id,
            seating: !seat
              ? { case: "seatCount", value: amount }
              : { case: "seatIds", value: { ids: [seat.id] } },
          })
          .then((res) => {
            return res as PlainMessage<ReserveSeatsResponse>;
          })
          .catch((err: TypeError) => {
            throw err;
          });

        if (response.reservationToken) {
          useCart.getState().setReservationToken(response.reservationToken);
        }

        if (response.seating.case === "seatCount") {
          const { value } = response.seating;

          if (value !== amount) {
            toast({
              description: `Die gewünschte Anzahl an Tickets konnte nicht reserviert werden.`,
              variant: "destructive",
            });
          }

          const index = storedItems.findIndex(
            (item) =>
              item.section.id === section.id &&
              item.price.id === price.id &&
              item.eventOrBundle.case === eventOrBundle.case &&
              item.eventOrBundle.value.id === eventOrBundle.value.id &&
              item.series.id === series.id &&
              item.seat?.id === seat?.id &&
              !item.discount,
          );

          if (index !== -1) {
            storedItems[index].quantity += value;
          } else {
            storedItems.push({
              section,
              price,
              eventOrBundle,
              series,
              quantity: value,
              upgrades,
            });
          }
        } else {
          const { value } = response.seating;

          if (seat && !value?.ids.includes(seat.id)) {
            toast({
              description: `Die gewünschte Anzahl an Tickets konnte nicht reserviert werden.`,
              variant: "destructive",
            });
          } else if (value) {
            storedItems.push({
              section,
              price,
              eventOrBundle,
              series,
              quantity: 1,
              seat,
              upgrades,
            });
          }
        }
      } else {
        const reserveConfigurations = createReserveOrCancelBundleRequest({
          section,
          price,
          eventOrBundle,
          series,
          quantity: amount,
          seat,
          discount,
          upgrades,
        });

        for (const reserveConfiguration of reserveConfigurations) {
          const bundleResponse = await reservation.reserveBundle({
            ...reserveConfiguration,
            reservationToken: useCart.getState().reservationToken ?? "",
          });

          if (bundleResponse.reservationToken) {
            useCart
              .getState()
              .setReservationToken(bundleResponse.reservationToken);
          }

          if (!seat) {
            const index = storedItems.findIndex(
              (item) =>
                item.section.id === section.id &&
                item.price.id === price.id &&
                item.eventOrBundle.case === eventOrBundle.case &&
                item.eventOrBundle.value.id === eventOrBundle.value.id &&
                item.series.id === series.id &&
                item.seat === undefined &&
                !item.discount,
            );

            if (index !== -1) {
              storedItems[index].quantity += 1;
            } else {
              storedItems.push({
                section,
                price,
                eventOrBundle,
                series,
                quantity: 1,
                upgrades,
              });
            }
          } else {
            storedItems.push({
              section,
              price,
              eventOrBundle,
              series,
              quantity: 1,
              seat,
              upgrades,
            });
          }
        }
      }
    }
  }

  const updatedItems = storedItems.filter(({ quantity }) => quantity > 0);

  if (updatedItems.length === 0) {
    useCart.setState({
      currency: "EUR",
      items: [],
      uiItems: [],
      discounts: {
        vouchers: [],
        benefits: [],
        giftcards: [],
      },
      cartTimer: null,
      reservationToken: null,
    });

    saveCartItems([]);
    saveDiscounts({
      vouchers: [],
      benefits: [],
      giftcards: [],
    });
  }

  return { items: updatedItems };
}

export const translateText = (
  data: PlainMessage<Text> | undefined | null,
): string => {
  if (!data) {
    return "";
  }

  const lng = getLocale();

  if (data.translations[lng]) {
    return data.translations[lng];
  }

  return data.translations[data.defaultLanguage];
};

export function getLocale(): string {
  if (typeof navigator === "undefined") return "";

  const browserLng = parse(navigator.language)[0]?.code;
  const cookies = new Cookies();
  const cookieLng = cookies.get(EGOSYS_LNG_COOKIE);

  const supportedLocales = proto3
    .getEnumType(ShopLanguage)
    .values.map((value) => value.localName.toLowerCase());

  const check = (lng: string) => {
    if (supportedLocales.includes(lng)) return lng;
    return "en";
  };

  if (cookieLng) return check(cookieLng);
  if (browserLng) return check(browserLng);
  return "en";
}

export const getHost = (): string => {
  const url = new URL(window.location.toString());
  return url.hostname;
};

export function isNecessaryAccepted(): boolean {
  const cookies = new Cookies();
  const settings = cookies.get(COOKIE_PREFERENCES_KEY);
  return Boolean(settings && settings.necessary);
}

export function persistShipmentMethod(method: ShipmentMethod) {
  const cookies = new Cookies();
  if (method === ShipmentMethod.UNSPECIFIED) {
    cookies.remove(SHIPMENT_METHOD_KEY, {
      path: "/",
      sameSite: "strict",
    });

    return;
  }

  cookies.set(SHIPMENT_METHOD_KEY, method.toString(), {
    path: "/",
    maxAge: DEFAULT_COOKIE_MAX_AGE,
    expires: DEFAULT_COOKIE_EXPIRE,
  });
}
