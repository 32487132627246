export enum Paths {
  ROOT = "/",
  ACCOUNT = "/account",
  ACCOUNT_ORDERS = "/account/orders",
  ACCOUNT_ORDER_DETAILS = "/account/orders/:orderId",
  ACCOUNT_PROFILE = "/account/profile",
  SIGN_UP = "/sign-up",
  SIGN_IN = "/sign-in",
  PASSWORD_RESET = "/password-reset",
  REQUEST_PASSWORD_RESET = "/request-password-reset",
  IMPRINT = "/imprint",
  PRIVACY = "/privacy",
  TERMS = "/terms",
  CART = "/cart",
  CHECKOUT = "/checkout",
  CHECKOUT_SIGNED_IN = "/checkout/signed-in",
  CHECKOUT_GUEST = "/checkout/guest",
  CHECKOUT_ORDERID = "/checkout/:orderId",
  SERIES = "/series/:seriesId",
  SERIES_ANCHOR_OVERVIEW = "/series/:seriesId#overview",
  TICKETS = "/tickets/:eventId",
  TICKETS_ANCHOR_BUY = "/tickets/:eventId#buy-tickets",
  TICKETS_BUNDLE = "/tickets/bundle/:bundleId",
  TICKETS_BUNDLE_ANCHOR_BUY = "/tickets/bundle/:bundleId#buy-tickets",
  UPCOMING_EVENTS = "/upcoming-events",
  EVENT = "/event/:eventId",
  EVENT_NOT_FOUND = "/event/:eventId/not-found",
  BUNDLE = "/bundle/:bundleId",
  BUNDLE_NOT_FOUND = "/bundle/:bundleId/not-found",
  ORDER_SUCCESS = "/order/:orderId/success",
  ORDER_VOIDED = "/order/:orderId/voided",
  ORDER_CAPTURE = "/order/:orderId/capture",
  ORDER_FAILED = "/order/:orderId/failed",
  ORDER_NOT_FOUND = "/order/:orderId/not-found",
  EMAIL_VERIFICATION = "/email-verification",
  GIFTCARDS = "/giftcards",
  FAQ = "/faq",
  CONTACT = "/contact",
  FANTICKET = "/fan-ticket"
}
